import React, { useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap';
import grayImage from "../../assets/img/Landing_Page/bg-gray-focus.png"
import greenImage from "../../assets/img/Landing_Page/bg-green-active.png"

const NFT = () => {

    const [active, setActive] = useState('')

    const [images, setImages] = useState([
        {
            src: require('../../assets/img/Landing_Page/nft1.jpg').default,
            title: "Fireza"
        },
        {
            src: require('../../assets/img/Landing_Page/nft2.jpg').default,
            title: "Topela"
        },
        {
            src: require('../../assets/img/Landing_Page/nft3.jpg').default,
            title: "Hawaiana"
        },
        {
            src: require('../../assets/img/Landing_Page/nft4.jpg').default,
            title: "Ariel"
        },
        {
            src: require('../../assets/img/Landing_Page/nft5.jpg').default,
            title: "Amirza"
        },
        {
            src: require('../../assets/img/Landing_Page/nft6.jpg').default,
            title: "Cuoco"
        }
    ]);
    
    return (
        <section id="nfts" className='position-rel'>
            <Row className='align-items-center'>
                <Col lg="2">
                    <img src={require('../../assets/img/Landing_Page/02.png').default} className="img-fluid d-none d-lg-inline" width='80px' />
                </Col>
                <Col lg="8" className='text-center'>
                    <h2 className='ff-medium text-dark heading-main'>NFTS</h2>
                    <p className='ff-regular text-gray para-main mb-4'>Discover PZZA NFTs: Dive into our flavorful world of NFTs with PZZA. Our exclusive collection of 5,000 pizza slice characters serves as a delightful loyalty program. Whenever you savor a slice or indulge in a whole pizza, you'll receive PZZA NFTs. </p>
                    <p className='ff-regular text-gray para-main'>Collect these digital tokens and experience the perks – from enjoying a free pizza to supporting carbon offset initiatives or gifting the joy of PZZA to someone special. With these NFTs, your PZZA experience goes beyond the plate, making every bite even more satisfying.</p>
                </Col>
                <Col lg="2"></Col>
            </Row>
            <div className='mt-5'>
                <Row>
                    {images.map((items, key) => (
                        <>
                            <Col md="6" xl="4" className='mt-3' data-aos="flip-left">
                                <div className={`nft-card px-4 py-3 ${active === (key + 1) ? 'bg-green-active' : 'bg-gray-focus'}`} onMouseOver={() => setActive(key + 1)} onMouseOut={() => setActive('')}>
                                    <div className='text-left'>
                                        <h3 className={`ff-semibold mt-2 ${active === (key + 1) ? 'text-white' : 'text-dark'}`} >{items.title}</h3>
                                    </div>
                                    <img src={items.src} className="img-fluid pt-4" />
                                </div>
                            </Col>
                        </>
                    ))}
                </Row>
            </div>
            <div className='d-none d-xl-flex position-timeline align-items-start'>
                <img src={require('../../assets/img/Landing_Page/nft-line.png').default} className="img-fluid" />
                <div className='mt-3 pl-2'>
                    <p className='text-dark'>01/About Us</p>
                    <p className='text-dark font-weight-bold mt-3'>02/NFTs</p>
                    <p className='text-dark mt-3'>03/Benefits</p>
                    <p className='text-dark mt-3'>04/Machine</p>
                    <p className='text-dark mt-3'>05/FAQs</p>
                </div>
            </div>
        </section>
    )
}

export default NFT