import React, { useState } from 'react'
import {
    Col, Row, Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem
} from 'reactstrap'

const About = () => {
    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };
    return (
        <>
            <section id="about" className='position-rel'>
                <div className='d-flex align-items-start'>
                    <div className='bg-about p-2 p-xl-5 d-flex align-items-center'>
                        <Row className='d-flex align-items-center justify-content-center'>
                            <Col lg="6" className='pl-xl-5' data-aos="zoom-in-up">
                                <h2 className='ff-medium text-dark heading-main'>About Us</h2>
                                <p className='ff-regular text-gray para-main'>PZZA (also referred to as “the Company”) is launching a new cutting-edge automated food preparation technology that could change the food service sector, starting with the pizza industry.</p>
                                <p className='ff-regular text-gray para-main my-4'>The Company’s tech-powered concept can standardize quality controls and increase efficiency while also reducing time, labor, waste, and overall costs for food manufacturers. PZZA will be initially launched in Finland with plans to expand into Europe and worldwide.</p>
                                <button className="btn-pzza py-3 px-3 px-xl-5 mt-4 mt-lg-0 ff-bold">CONTACT US</button>
                            </Col>
                            <Col lg="6" className='mt-5 mt-lg-0' data-aos="zoom-out">
                                <div>
                                    <Accordion open={open} toggle={toggle}>
                                        <AccordionItem>
                                            <AccordionHeader targetId="1" className='ff-medium'>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <p className='ff-medium text-dark about-section-head'>About PZZA</p>
                                                    {open === "1" ? <img src={require('../../assets/img/Landing_Page/collapse-out.png').default} className="img-fluid" /> : <img src={require('../../assets/img/Landing_Page/collapse-in.png').default} className="img-fluid" />}
                                                </div>
                                            </AccordionHeader>
                                            <AccordionBody accordionId="1">
                                                <p className='ff-regular text-gray'>Welcome to the future of food service! PZZA, our groundbreaking company, is driving a revolution in the culinary world with cutting-edge automated food preparation technology. Our journey begins in Finland, where we're launching a game-changing concept set to reshape the pizza industry.</p>
                                            </AccordionBody>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionHeader targetId="2" className='ff-medium'>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <p className='ff-medium text-dark about-section-head'>A Taste of Tomorrow</p>
                                                    {open === "2" ? <img src={require('../../assets/img/Landing_Page/collapse-out.png').default} className="img-fluid" /> : <img src={require('../../assets/img/Landing_Page/collapse-in.png').default} className="img-fluid" />}
                                                </div>
                                            </AccordionHeader>
                                            <AccordionBody accordionId="2">
                                                <p className='ff-regular text-gray'>At PZZA, we harness the power of technology to create a seamless, efficient, and waste-reducing food preparation experience. Our proprietary technology, designed to perfection, standardizes quality controls and enhances efficiency. The best part? It significantly reduces labor requirements and operational costs for food manufacturers.</p>
                                            </AccordionBody>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionHeader targetId="3" className='ff-medium'>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <p className='ff-medium text-dark about-section-head'>The Magic Behind PZZA</p>
                                                    {open === "3" ? <img src={require('../../assets/img/Landing_Page/collapse-out.png').default} className="img-fluid" /> : <img src={require('../../assets/img/Landing_Page/collapse-in.png').default} className="img-fluid" />}
                                                </div>
                                            </AccordionHeader>
                                            <AccordionBody accordionId="3">
                                                <p className='ff-regular text-gray'>PZZA’s innovative technology minimizes kitchen staff, requiring only a single employee to work alongside our automated assembly system. Our smart machine meticulously applies sauce and a variety of toppings, then skillfully bakes the pizza to perfection. In just six minutes, it produces a fully-cooked pie that’s consistently delightful.</p>
                                            </AccordionBody>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionHeader targetId="4" className='ff-medium'>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <p className='ff-medium text-dark about-section-head'>Personalized Pizza</p>
                                                    {open === "4" ? <img src={require('../../assets/img/Landing_Page/collapse-out.png').default} className="img-fluid" /> : <img src={require('../../assets/img/Landing_Page/collapse-in.png').default} className="img-fluid" />}
                                                </div>
                                            </AccordionHeader>
                                            <AccordionBody accordionId="4">
                                                <p className='ff-regular text-gray'>Customization takes center stage at PZZA. Our future restaurants will feature user-friendly touch-screen kiosks, allowing you to design your dream pizza, selecting dough shapes, toppings, and sauces. Here's where the magic happens: our AI-powered kiosks read your mood and suggest the perfect pizza, catering to your cravings.</p>
                                            </AccordionBody>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionHeader targetId="5" className='ff-medium'>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <p className='ff-medium text-dark about-section-head'>A Culinary Adventure</p>
                                                    {open === "5" ? <img src={require('../../assets/img/Landing_Page/collapse-out.png').default} className="img-fluid" /> : <img src={require('../../assets/img/Landing_Page/collapse-in.png').default} className="img-fluid" />}
                                                </div>
                                            </AccordionHeader>
                                            <AccordionBody accordionId="5">
                                                <p className='ff-regular text-gray'>PZZA isn’t just a place to eat; it's a culinary adventure. Beyond custom pizzas, our locations will feature monthly creations by our master chef and playful, festive pizzas for special occasions. The goal is to provide an unforgettable dining experience whether it’s a romantic date, a quick work lunch, or quality time with friends and family.</p>
                                            </AccordionBody>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionHeader targetId="6" className='ff-medium'>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <p className='ff-medium text-dark about-section-head'>Beyond the Plate</p>
                                                    {open === "6" ? <img src={require('../../assets/img/Landing_Page/collapse-out.png').default} className="img-fluid" /> : <img src={require('../../assets/img/Landing_Page/collapse-in.png').default} className="img-fluid" />}
                                                </div>
                                            </AccordionHeader>
                                            <AccordionBody accordionId="6">
                                                <p className='ff-regular text-gray'>Expanding our reach is essential, so we’re taking our delicious creations to local groceries and supermarkets. Here, you can grab our cold pizzas, all conveniently packaged in biodegradable, eco-friendly materials. Our automated food preparation technology can churn out 1,000 custom pizzas daily, ensuring a slice of PZZA is always within reach.</p>
                                            </AccordionBody>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionHeader targetId="7" className='ff-medium'>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <p className='ff-medium text-dark about-section-head'>A Delicious Future</p>
                                                    {open === "7" ? <img src={require('../../assets/img/Landing_Page/collapse-out.png').default} className="img-fluid" /> : <img src={require('../../assets/img/Landing_Page/collapse-in.png').default} className="img-fluid" />}
                                                </div>
                                            </AccordionHeader>
                                            <AccordionBody accordionId="7">
                                                <p className='ff-regular text-gray'>As we forge ahead, our advanced technology will extend its capabilities, assembling an array of delectable cuisines beyond pizza. Ramen, bowls, salads, and much more will join our mouthwatering menu in the future.</p>
                                            </AccordionBody>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionHeader targetId="8" className='ff-medium'>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <p className='ff-medium text-dark about-section-head'>Join the PZZA Experience</p>
                                                    {open === "8" ? <img src={require('../../assets/img/Landing_Page/collapse-out.png').default} className="img-fluid" /> : <img src={require('../../assets/img/Landing_Page/collapse-in.png').default} className="img-fluid" />}
                                                </div>
                                            </AccordionHeader>
                                            <AccordionBody accordionId="8">
                                                <p className='ff-regular text-gray'>Stay tuned for our exciting NFT collection featuring 5,000 unique pizza slice characters. These NFTs serve as a loyalty program, granting incredible perks to our beloved customers. With every slice you enjoy or share, you'll earn PZZA NFTs. Collect them to redeem for a free pizza, contribute to carbon offset initiatives, or give the gift of PZZA to someone special. Our commitment to innovation extends beyond the kitchen and into the digital realm.</p>
                                                <p className='ff-regular text-gray'>Join us on our culinary journey and get ready to savor the flavors of the future with PZZA!</p>
                                            </AccordionBody>
                                        </AccordionItem>
                                    </Accordion>
                                </div>
                            </Col>
                        </Row>
                        <div className='position-no1 d-none d-lg-block'>
                            <img src={require('../../assets/img/Landing_Page/01.png').default} className="img-fluid" width='80px' />
                        </div>
                    </div>
                </div>
                <div className='d-none d-xl-flex position-timeline align-items-start'>
                    <img src={require('../../assets/img/Landing_Page/about-line.png').default} className="img-fluid" />
                    <div className='mt-3 pl-2'>
                        <p className='text-dark font-weight-bold'>01/About Us</p>
                        <p className='text-dark mt-3'>02/NFTs</p>
                        <p className='text-dark mt-3'>03/Benefits</p>
                        <p className='text-dark mt-3'>04/Machine</p>
                        <p className='text-dark mt-3'>05/FAQs</p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About