import Footer from "components/Footer";
import React from "react";
import About from "./About";
import Banner from "./Banner";
import Benefits from "./Community";
import FAQ from "./FAQ";
import NFT from "./NFT";
import Roadmap from "./Roadmap";

export default function Index() {
    React.useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    return (
        <>
            <div className="wrapper container" >
                <Banner />
                <About />
                <NFT />
                <Benefits />
                <Roadmap />
                <FAQ />
            </div>
            <Footer />
        </>
    );
}