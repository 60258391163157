import React from 'react'
import { Col, Row } from 'reactstrap'

const Roadmap = () => {
    return (
        <>
            <section id="roadmap" className='position-rel'>
                <Row className='align-items-center'>
                    <Col lg="5" className='text-center text-lg-left'>
                        <img src={require('../../assets/img/Landing_Page/04-image.jpg').default} className="img-fluid" />
                    </Col>
                    <Col lg="7" className='mt-4 mt-lg-0'>
                        <h2 className='ff-medium text-dark heading-main'>The PZZA Machine</h2>
                        <p className='ff-regular text-gray para-main pr-4'>PZZA's cutting-edge pizza bot, currently patent-pending, boasts a sleek conveyor system that glides beneath high-tech stainless-steel ingredient vaults. Not just that, it's got precision-driven dispensers for the perfect pizza toppings, a cool mechanism that effortlessly pops the pizza into the oven, and a finisher move with its pro slicer. Pizza tech has never been this awesome!</p>
                    </Col>
                </Row>
                <div className='position-no4 d-none d-lg-block'>
                    <img src={require('../../assets/img/Landing_Page/04.png').default} className="img-fluid" width='80px' />
                </div>
                {/* <Row className='align-items-center'>
                    <Col lg="2">
                        <img src={require('../../assets/img/Landing_Page/04.png').default} className="img-fluid" width='80px' />
                    </Col>
                    <Col lg="8" className='text-center'>
                        <h2 className='ff-medium text-dark heading-main'>Roadmap</h2>
                        <p className='ff-regular text-gray para-main'>Lorem ipsum dolor sit amet consectetur. Amet morbi sit suspendisse dui ut donec vel id. Viverra urna cras nulla elementum. Risus orci dolor euismod in fringilla adipiscing eu condimentum. </p>
                    </Col>
                    <Col lg="2"></Col>
                </Row>
                <Row className='justify-content-center align-items-center mt-5'>
                    <Col lg="4">
                        <div className='d-flex flex-column align-items-center'>
                            <img src={require('../../assets/img/Landing_Page/active-pizza.png').default} className="img-fluid" />
                            <img src={require('../../assets/img/Landing_Page/active-wave.png').default} className="img-fluid" />
                        </div>
                    </Col>
                    <Col lg="8">
                        <div className='position-rel'>
                            <img src={require('../../assets/img/Landing_Page/r-01.png').default} className="img-fluid" />
                            <div className='position-launch'>
                                <img src={require('../../assets/img/Landing_Page/launch-white.png').default} className="img-fluid" />
                                <div className='text-left mt-4'>
                                    <h3 className={`ff-semibold mb-2 text-white`} >Launch</h3>
                                    <p className={`ff-regular w-50 text-white`}>Lorem ipsum dolor sit amet consectetur. Amet morbi sit suspendisse dui ut donec vel id. Viverra urna cras nulla elementum. Risus orci dolor euismod in fringilla adipiscing eu condimentum. </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg="4">
                        <div className='d-flex flex-column align-items-center'>
                            <img src={require('../../assets/img/Landing_Page/pizza1.png').default} className="img-fluid" />
                            <img src={require('../../assets/img/Landing_Page/wave2.png').default} className="img-fluid" />
                        </div>
                    </Col>
                    <Col lg="8">
                        <div className='position-rel'>
                            <img src={require('../../assets/img/Landing_Page/r-02.png').default} className="img-fluid" />
                            <div className='position-launch'>
                                <img src={require('../../assets/img/Landing_Page/gateway.png').default} className="img-fluid" />
                                <div className='text-left mt-4'>
                                    <h3 className={`ff-semibold mb-2 text-gray`} >Community Giveaway</h3>
                                    <p className={`ff-regular w-50 text-gray`}>Lorem ipsum dolor sit amet consectetur. Amet morbi sit suspendisse dui ut donec vel id. Viverra urna cras nulla elementum. Risus orci dolor euismod in fringilla adipiscing eu condimentum. </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg="4">
                        <div className='d-flex flex-column align-items-center'>
                            <img src={require('../../assets/img/Landing_Page/pizza2.png').default} className="img-fluid" />
                            <img src={require('../../assets/img/Landing_Page/wave3.png').default} className="img-fluid" />
                        </div>
                    </Col>
                    <Col lg="8">
                        <div className='position-rel'>
                            <img src={require('../../assets/img/Landing_Page/r-03.png').default} className="img-fluid" />
                            <div className='position-launch'>
                                <img src={require('../../assets/img/Landing_Page/merchandise.png').default} className="img-fluid" />
                                <div className='text-left mt-4'>
                                    <h3 className={`ff-semibold mb-2 text-gray`} >Merchandise Drop</h3>
                                    <p className={`ff-regular w-50 text-gray`}>Lorem ipsum dolor sit amet consectetur. Amet morbi sit suspendisse dui ut donec vel id. Viverra urna cras nulla elementum. Risus orci dolor euismod in fringilla adipiscing eu condimentum. </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg="4">
                        <div className='d-flex flex-column align-items-center'>
                            <img src={require('../../assets/img/Landing_Page/pizza3.png').default} className="img-fluid" />
                            <img src={require('../../assets/img/Landing_Page/wave2.png').default} className="img-fluid" />
                        </div>
                    </Col>
                    <Col lg="8">
                        <div className='position-rel'>
                            <img src={require('../../assets/img/Landing_Page/r-04.png').default} className="img-fluid" />
                            <div className='position-launch'>
                                <img src={require('../../assets/img/Landing_Page/collab.png').default} className="img-fluid" />
                                <div className='text-left mt-4'>
                                    <h3 className={`ff-semibold mb-2 text-gray`} >Collaboration</h3>
                                    <p className={`ff-regular w-50 text-gray`}>Lorem ipsum dolor sit amet consectetur. Amet morbi sit suspendisse dui ut donec vel id. Viverra urna cras nulla elementum. Risus orci dolor euismod in fringilla adipiscing eu condimentum. </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg="4">
                        <div className='d-flex flex-column align-items-center'>
                            <img src={require('../../assets/img/Landing_Page/pizza4.png').default} className="img-fluid" />
                        </div>
                    </Col>
                    <Col lg="8">
                        <div className='position-rel'>
                            <img src={require('../../assets/img/Landing_Page/r-05.png').default} className="img-fluid" />
                            <div className='position-launch'>
                                <img src={require('../../assets/img/Landing_Page/drops.png').default} className="img-fluid" />
                                <div className='text-left mt-4'>
                                    <h3 className={`ff-semibold mb-2 text-gray`} >Future Drops</h3>
                                    <p className={`ff-regular w-50 text-gray`}>Lorem ipsum dolor sit amet consectetur. Amet morbi sit suspendisse dui ut donec vel id. Viverra urna cras nulla elementum. Risus orci dolor euismod in fringilla adipiscing eu condimentum. </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row> */}
                <div className='d-none d-xl-flex position-timeline align-items-start'>
                    <img src={require('../../assets/img/Landing_Page/machine-line.png').default} className="img-fluid" />
                    <div className='mt-3 pl-2'>
                        <p className='text-dark'>01/About Us</p>
                        <p className='text-dark mt-3'>02/NFTs</p>
                        <p className='text-dark mt-3'>03/Benefits</p>
                        <p className='text-dark mt-3 font-weight-bold'>04/Machine</p>
                        <p className='text-dark mt-3'>05/FAQs</p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Roadmap