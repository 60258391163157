import React, { useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap';
import Carousel from 'react-elastic-carousel';

const Benefits = () => {


    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 4 },
        { width: 768, itemsToShow: 4 },
        { width: 1200, itemsToShow: 4 }
    ]
    const [active, setActive] = useState('')

    const [timerDays, setTimerDays] = useState(0);
    const [timerHours, setTimerHours] = useState(0);
    const [timerMinutes, setTimerMinutes] = useState(0);
    const [timerSeconds, setTimerSeconds] = useState(0);
    
    return (
        <section id="benefits" className='position-rel'>
            <div className='bg-about p-2 p-xl-5 d-flex align-items-center flex-column'>
                <Row className='align-items-center'>
                    <Col lg="2">
                    </Col>
                    <Col lg="8" className='text-center'>
                        <h2 className='ff-medium text-dark heading-main'>Benefits</h2>
                        {/* <p className='ff-regular text-gray para-main'>Lorem ipsum dolor sit amet consectetur. Amet morbi sit suspendisse dui ut donec vel id. Viverra urna cras nulla elementum. Risus orci dolor euismod in fringilla adipiscing eu condimentum. </p> */}
                    </Col>
                    <Col lg="2"></Col>
                </Row>
                <Carousel itemsToShow={1}  >
                    <Row className='d-flex align-items-center justify-content-center mt-5'>
                        <Col md="6" className='order-2 order-md-1 mt-5 mt-md-0' data-aos="zoom-in-up">
                            <div className='text-left'>
                                <h3 className={`ff-semibold mb-2 text-gray`} >Savor a Free PZZA Slice</h3>
                                <p className={`ff-regular text-gray`}>With 8 PZZA NFT slices in your digital wallet, you unlock the delectable pleasure of a free pizza pie. It's our way of saying thank you for your loyalty, and a chance for you to enjoy a mouthwatering pizza without spending a dime. So, collect your NFTs and savor the taste of PZZA on us.</p>
                            </div>
                        </Col>
                        <Col md="6" className='order-1 order-md-2' data-aos="zoom-out">
                            <img src={require('../../assets/img/Landing_Page/benefit1.png').default} className="img-fluid" />
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center mt-5'>
                        <Col md="6" className='order-2 order-md-1 mt-5 mt-md-0' data-aos="zoom-in-up">
                            <div className='text-left'>
                                <h3 className={`ff-semibold mb-2 text-gray`} >Give the Gift of PZZA</h3>
                                <p className={`ff-regular text-gray`}>Sharing is caring, and with 8 PZZA NFT slices, you can spread the joy of PZZA. Gift a slice or an entire pizza to your friends, family, or someone special. It's a delicious way to celebrate and create unforgettable moments, all through the power of your NFTs.</p>
                            </div>
                        </Col>
                        <Col md="6" className='order-1 order-md-2' data-aos="zoom-out">
                            <img src={require('../../assets/img/Landing_Page/benefit1.png').default} className="img-fluid" />
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center mt-5'>
                        <Col md="6" className='order-2 order-md-1 mt-5 mt-md-0' data-aos="zoom-in-up">
                            <div className='text-left'>
                                <h3 className={`ff-semibold mb-2 text-gray`} >Offset Your Carbon Footprint</h3>
                                <p className={`ff-regular text-gray`}>At PZZA, we're dedicated to sustainability. With 8 PZZA NFT slices, you have the opportunity to give back to the environment. Redeem your NFTs to donate carbon credits and offset your carbon footprint. Every slice counts, not only in flavor but in making a positive impact on the planet.</p>
                            </div>
                        </Col>
                        <Col md="6" className='order-1 order-md-2' data-aos="zoom-out">
                            <img src={require('../../assets/img/Landing_Page/benefit1.png').default} className="img-fluid" />
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center justify-content-center mt-5'>
                        <Col md="6" className='order-2 order-md-1 mt-5 mt-md-0' data-aos="zoom-in-up">
                            <div className='text-left'>
                                <h3 className={`ff-semibold mb-2 text-gray`} >Enhance Your Collection</h3>
                                <p className={`ff-regular text-gray`}>As a PZZA NFT holder, growing your digital slice collection is a fun and rewarding experience. With 8 NFT slices, you're well on your way to building a unique assortment of pizza-inspired art. Whether you're a dedicated collector or a casual enthusiast, your PZZA NFTs add flavor to your digital portfolio.</p>
                            </div>
                        </Col>
                        <Col md="6" className='order-1 order-md-2' data-aos="zoom-out">
                            <img src={require('../../assets/img/Landing_Page/benefit1.png').default} className="img-fluid" />
                        </Col>
                    </Row>
                </Carousel>
                <div className='position-no1 d-none d-lg-block'>
                    <img src={require('../../assets/img/Landing_Page/03.png').default} className="img-fluid" width='80px' />
                </div>
            </div>
            <div className='d-none d-xl-flex position-timeline align-items-start'>
                <img src={require('../../assets/img/Landing_Page/benefit-line.png').default} className="img-fluid" />
                <div className='mt-3 pl-2'>
                    <p className='text-dark'>01/About Us</p>
                    <p className='text-dark mt-3'>02/NFTs</p>
                    <p className='text-dark font-weight-bold mt-3'>03/Benefits</p>
                    <p className='text-dark mt-3'>04/Machine</p>
                    <p className='text-dark mt-3'>05/FAQs</p>
                </div>
            </div>
        </section >

    )
}

export default Benefits