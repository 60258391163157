/*!

=========================================================
* exceed - v1.0.0
=========================================================
* Coded by Ecrubit
*/
import React from "react";
// reactstrap components
import {
    Row,
    Col
} from "reactstrap";
import { AnchorScroll } from "helper";

export default function Footer() {
    return (
        <div className="footer-bg">
            <div className="py-4">
                <div className="container mt-3 border-bottom-style">
                    <Row className="pb-3 justify-content-center align-items-center">
                        <Col md="3" sm="12" xs="12" className=" mt-3 mt-lg-0 d-flex flex-row justify-content-center justify-content-lg-start">
                            <a href="#"><img src={require('../../assets/img/Landing_Page/pzza-logo-footer.png').default} alt='logo' className='img-fluid' width={180} /></a>
                        </Col>
                        <Col md="3" sm="12" xs="12" className="mt-5 mt-lg-0">
                            <div className="nav-link-space footer-text d-flex flex-column align-items-center justify-content-center align-items-md-left justify-content-md-left text-primary">
                                <h4 className="ff-medium text-white">Links</h4>
                                <a href="/#collection" className="text-white ff-regular px-3 py-3">Home</a>
                                <a href="/#about" className="text-white ff-regular px-3 py-3">About Us</a>
                                <a href="/#nfts" className="text-white ff-regular px-3 py-3">NFTs</a>
                                <a href="/#benefits" className="text-white ff-regular px-3 py-3">Benefits</a>
                                {/* <a href="/#roadmap" className="text-white ff-regular px-3 py-3">Roadmap</a> */}
                                <a href="/#faqs" className="text-white ff-regular px-3 py-3">FAQs</a>
                            </div>
                        </Col>
                        <Col md="6" sm="12" xs="12" className="mt-3 mt-lg-0 text-center text-md-left">
                            <div className="text-center">
                                <h4 className="ff-medium text-white mt-3">Follow Us</h4>
                            </div>
                            <div className="social-div d-flex justify-content-center">
                                <a href="https://twitter.com/" target={'_blank'} rel='noreferrer'>
                                    <img src={require('../../assets/img/Landing_Page/foot-twit.png').default} alt='logo' className='img-fluid' width={50} />
                                </a>
                                <a href="https://www.linkedin.com/in/ai-qo-74a639298/" target={'_blank'} rel='noreferrer'>
                                    <img src={require('../../assets/img/Landing_Page/foot-insta.png').default} alt='logo' className='img-fluid' width={50} />
                                </a>
                            </div>
                        </Col>
                    </Row>

                </div>
                <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-between align-items-center container ">
                    <div className="ff-regular text-white pt-4">
                        Aiqo ltd all rights reserved.
                    </div>
                    <div className="ff-regular text-white d-flex justify-content-center align-items-center pt-4 cursor-pointer" onClick={() => AnchorScroll()}>
                        <h5 className="mb-0 mr-3">
                            Back To Top
                        </h5>

                        <img src={require('../../assets/img/Landing_Page/back-to-top.png').default} alt='logo' className='img-fluid' width={60} />
                    </div>
                </div>
            </div >
        </div>
    );
}
