import { Row, Col } from "reactstrap";
import IndexNavbar from "components/Navbars/IndexNavbar";

export default function Banner() {
    return (
        <div>
            <div>
                <IndexNavbar className="fixed-top" />
            </div>
            <section>
                <div className="banner-mobile pt-0">
                    <Row className="d-flex align-items-center justify-content-center justify-content-lg-center">
                        <Col lg="7" xs="12" className="text-center text-lg-left">
                            <div className="text-height d-flex flex-column ">
                                <span className="d-none d-sm-inline text-banner ff-medium mb-5 mb-lg-4 mr-1">PZZA NFTs</span>
                                <span className="d-inline d-sm-none text-xs-banner ff-medium mr-1">PZZA NFTs</span>
                                <div className="d-flex">
                                    {/* <img src={require('../../assets/img/Landing_Page/banner-foot.png').default} className="img-fluid mb-sm-4" /> */}
                                    <div className="d-none d-sm-flex align-items-center banner-nfts">
                                        <img src={require('../../assets/img/Landing_Page/nft1.jpg').default} className="img-fluid" />
                                        <img src={require('../../assets/img/Landing_Page/nft3.jpg').default} className="img-fluid" />
                                        <img src={require('../../assets/img/Landing_Page/nft4.jpg').default} className="img-fluid" />
                                        <img src={require('../../assets/img/Landing_Page/circle4.png').default} className="img-fluid" />
                                    </div>
                                    <span className="d-none d-sm-inline text-banner mb-0 ff-medium">Slices of</span>
                                    <span className="text-xs-banner w-100 d-inline d-sm-none mb-0 ff-medium">Slices of</span>
                                </div>
                                <div className="mt-lg-4">
                                    <img src={require('../../assets/img/Landing_Page/uparrow.png').default} className="img-fluid mb-sm-5" /><span className="ml-2 d-none d-sm-inline  text-banner mb-0 ff-medium">Innovation</span><span className="ml-2 text-xs-banner d-inline d-sm-none mb-0 ff-medium">Innovation</span>
                                </div>
                            </div>
                        </Col>
                        <Col lg="5" xs="12" className="text-center text-lg-left">
                            <img src={require('../../assets/img/Landing_Page/banner-right.png').default} className="img-fluid" />
                        </Col>
                    </Row>
                </div>
            </section>
        </div>

    )
}