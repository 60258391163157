import React, { useState } from 'react'
import { Row, Col } from 'reactstrap'
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai"

const FAQ = () => {

    const [active, setIsActive] = useState(null)

    const toggle = (i) => {
        if (active === i) {
            return setIsActive(null)
        }
        setIsActive(i)
    }
    const data = [{
        question: 'What is a PZZA NFT?',
        answer: <div><div className="mb-2">A PZZA NFT is a unique digital collectible that represents a slice of our flavorful pizza-inspired art. It's not just a token; it's a slice of PZZA magic.</div></div>
    },
    {
        question: 'How do I purchase a PZZA NFT?',
        answer: <div><div className="mb-2">Purchasing a PZZA NFT is easy - On the day of mint, connect your wallet in the upper right corner, follow the steps, and make your desired slice yours.</div></div>
    },
    {
        question: 'How do I set up a wallet to buy a PZZA NFT?',
        answer: <div><div className="mb-2">Setting up a wallet is essential for NFT transactions. You can choose from various wallet providers and create an account. Once your wallet is set up, you'll be ready to dive into the world of PZZA NFTs.</div></div>
    },
    {
        question: 'What can I do with my PZZA NFT?',
        answer: <div><div className="mb-2">Your PZZA NFT isn't just a digital slice; it's a key to a world of flavor. Collect, trade, and enjoy them in your digital wallet. Plus, owning 8 slices unlocks special benefits like free pizza and more.</div></div>
    },
    {
        question: 'How do I redeem my PZZA NFTs for a free pizza?',
        answer: <div><div className="mb-2">When you've collected 8 PZZA NFT slices, visit our redemption portal and follow the simple instructions to claim your free pizza. It's our way of thanking you for your loyalty.</div></div>
    },
    {
        question: 'Can I gift a PZZA NFT to someone else?',
        answer: <div><div className="mb-2">Absolutely! Gifting a PZZA NFT is a delightful way to share the joy of pizza. Simply send the NFT from your wallet to the recipient's wallet address and add a personal touch.</div></div>
    },
    {
        question: 'What happens if I lose access to my PZZA NFTs?',
        answer: <div><div className="mb-2">It's important to keep your NFT wallet information secure. If you lose access, you may lose your NFTs. Be sure to back up your wallet and keep your access information safe.</div></div>
    },
    {
        question: 'Do PZZA NFTs have any real-world utility or value?',
        answer: <div><div className="mb-2">PZZA NFTs offer a unique loyalty program. When you own 8 slices, you can redeem them for real-world benefits like free pizza, carbon offset donations, or gifting pizzas to others.</div><div className="mb-2">It's more than just a digital collectible; it's a taste of PZZA rewards.</div></div>
    }]

    return (
        <div className='bg-faq position-rel' id="faqs">
            <section>
                <div className='bg-about p-2 p-xl-5 d-flex align-items-center flex-column'>
                    <Row className='align-items-center'>
                        <Col lg="1">
                        </Col>
                        <Col lg="10" className='text-center pl-lg-5'>
                            <h2 className='ff-medium text-dark heading-main'>Frequently asked questions</h2>
                            <p className='ff-regular text-gray para-main'>Have questions? We've got answers! Check out our frequently asked questions to learn more about PZZA and how you can get your slice of the pie.</p>
                        </Col>
                        <Col lg="1"></Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            {data.map((item, i) => (
                                <div className="mt-4" >
                                    <div data-aos="fade-down-left" className="faq-bg py-3 d-flex justify-content-between align-items-center cursor-pointer" onClick={() => toggle(i)}>
                                        <h3 className="ff-semibold text-dark mb-0 about-section-head">
                                            {item.question}
                                        </h3>
                                        {active === i ? <img src={require('../../assets/img/Landing_Page/collapse-out.png').default} className="img-fluid" /> : <img src={require('../../assets/img/Landing_Page/collapse-in.png').default} className="img-fluid" />}
                                    </div>
                                    <div className={`faq-ans ${active === i ? 'content show' : 'content'}`}>
                                        {item.answer}
                                    </div>
                                </div>
                            ))}
                        </Col>
                    </Row>
                    <div className='position-no1 d-none d-lg-block'>
                        <img src={require('../../assets/img/Landing_Page/05.png').default} className="img-fluid" />
                    </div>
                </div>
            </section>
            <div className='d-none d-xl-flex position-timeline align-items-start'>
                <img src={require('../../assets/img/Landing_Page/faq-line.png').default} className="img-fluid" />
                <div className='mt-3 pl-2'>
                    <p className='text-dark'>01/About Us</p>
                    <p className='text-dark mt-3'>02/NFTs</p>
                    <p className='text-dark mt-3'>03/Benefits</p>
                    <p className='text-dark mt-3'>04/Machine</p>
                    <p className='text-dark font-weight-bold mt-3'>05/FAQs</p>
                </div>
            </div>
        </div>
    )
}

export default FAQ